import { graphql, PageProps } from "gatsby";
import React from "react";
import "twin.macro";
import { SEO } from "../components";
import { BlankPageNoImage } from "../components/common";
import { IStrapiPage } from "../utils/types";

const Terms: React.FC<PageProps<{ strapiTerms: IStrapiPage }>> = ({
  data: {
    strapiTerms: {
      title,
      content: {
        data: { content },
      },
    },
  },
}) => {
  return (
    <>
      <SEO title="Terms" />
      <BlankPageNoImage header={title} ariaLabel={title}>
        <div
          tw="prose font-light mx-auto"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </BlankPageNoImage>
    </>
  );
};

export default Terms;

export const query = graphql`
  query termspagequery {
    strapiTerms {
      content {
        data {
          content
        }
      }
      title
    }
  }
`;
